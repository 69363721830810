import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faBars, faX } from '@fortawesome/free-solid-svg-icons';
import installationPhoto from './media/installation.jpeg'
import repairPhoto from './media/repair.jpeg';
import salesPhoto from './media/sales.jpeg';
import logo from './media/figueroalogo.png'
import { useEffect, useState } from 'react';

function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  useEffect(() => {
    console.log("mobile menu open: " + mobileMenuOpen)
  }, [mobileMenuOpen])


  return (
    <div className="app" >
      {
        mobileMenuOpen &&
        <div className='mobile-menu'>
          <div className='x-out-div'><button onClick={() => setMobileMenuOpen(false)}>
            <FontAwesomeIcon icon={faX} style={{ color: "ffffff", height: 25 }}></FontAwesomeIcon>
          </button></div>

          <ul className='mobile-menu-list'>
            <li><a onClick={() => setMobileMenuOpen(false)} href='#top-of-page'>HOME</a></li>
            <li><a onClick={() => setMobileMenuOpen(false)} href='#services-section'>SERVICES</a></li>
            <li><a onClick={() => setMobileMenuOpen(false)} href='#contact-section'>CONTACT</a></li>
          </ul>

        </div>
      }

      <header>
        <div className='header-div'>
          <img className="header-logo" src={logo} />
          <ul className='header-menu'>
            <li><a href='#top-of-page'>HOME</a></li>
            <li><a href='#services-section'>SERVICES</a></li>
            <li><a href='#contact-section'>CONTACT</a></li>
          </ul>
          <div className='mobile-menu-button-container'>
            <button onClick={() => setMobileMenuOpen(true)}>
              <FontAwesomeIcon icon={faBars} style={{ color: "ffffff", height: 45 }}></FontAwesomeIcon>
            </button></div>

        </div>

      </header>
      <main>
        <div id="top-of-page" className='anchor'></div>
        <div id="hero-section" className='section odd-section'>
          <div className='hero-text'>
            <h2>FAST, FRIENDLY, RELIABLE HVAC SERVICES IN TUCSON, AZ</h2>
            <FontAwesomeIcon icon={faChevronDown} style={{ color: "#1A1A44", width: 50, height: 50 }} />
          </div>
          <div className='greyed-filter'></div>
          <div className='parallax parallax-workers'></div>
          <div id="services-section" className='anchor'></div>
        </div>
        <div className='services-section section even-section'>
          <div className='section-heading flex-column'>
            <h3>SERVICES</h3>
            <div className='separator'></div>
            <p className='important-text'>Ask about our FREE inspections!</p>
          </div>

          <div className='flex-row services-display'>
            <div className='flex-column service-card'>
              <div className='image-container'>
                <img src={salesPhoto} />
              </div>

              <h4>Sales</h4>
              <p>
                Discover top-notch HVAC solutions tailored to your needs. We offer a range of heating, ventilation, and air conditioning services. With a focus on customer satisfaction, trust us to optimize your indoor climate. </p>
            </div>
            <div className='flex-column service-card'>
              <div className='image-container'>
                <img src={installationPhoto} />
              </div>

              <h4>Installation</h4>
              <p>
                Rely on our skilled team for seamless HVAC installations. We specialize in precise fitting of heating, ventilation, and air conditioning systems. Our meticulous approach ensures optimal performance and energy efficiency in every installation. With attention to detail and industry expertise, we guarantee a hassle-free setup tailored to your space. </p>
            </div>
            <div className='flex-column service-card'>
              <div className='image-container'>
                <img src={repairPhoto} />
              </div>

              <h4>Repair</h4>
              <p>
                Our seasoned technicians diagnose and fix heating, ventilation, or air conditioning problems efficiently. We prioritize quick turnaround times without compromising on quality, ensuring your system operates at its best. Trust us for dependable repairs that restore comfort to your space efficiently and effectively.</p>
            </div>
          </div>
        </div>
        <div id="contact-section" className='section odd-section flex-column'>
          <div className='section-heading flex-column'>
            <h3>CONTACT</h3>
            <div className='separator'></div>
          </div>
          <div className='flex-column contact-info'>
            <p>Phone: 520.551.4598</p>
            <p>E-mail: figueroa_hvac@outlook.com</p>
            <p>Address: 903 W Miracle Mile, Tucson, AZ 85705</p>
          </div>
        </div>

        <footer>A website created by <a href="https://www.lindsaykjohnston.com/" rel='noreferrer' target='__blank'>Lindsay K. Johnston</a>.</footer>
      </main>
    </div>
  );
}

export default App;
